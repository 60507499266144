/* About page css */
.container{
    width: 100%;
    display:flex;
    flex-direction: column;
}
.overview, .infra{
    display: flex;
}
.image-box{
    width: 50%;
}

.image-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-box img{
    width: 100%;
    display: block;
}
.content{
    width: 50%;
    padding-top: 1rem;
    background-color: rgb(7, 65, 83);
    color: #fff;
    text-align: center;
}
.content > p{
    width: 90%;
    margin:1rem auto;
    word-break: normal;
    text-align: justify;
}

/* Message section */
.message-container{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
.placement-officer, .headUPC, .hod{
    width:100%;
    box-shadow: rgba(0, 0, 0, 0.473) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    margin-bottom: 30px;
    border-radius:10px;
    overflow: hidden;
}

.image-section{
    width: 30%;
}
.image-section img{
    width: 100%;
    margin:auto;
    display: block;
    filter: grayscale(0%);
}
.info{
    width: 100%;
    text-align: center;
}
.info h2{
    color:rgb(7, 65, 83);
    margin: 0.3rem 0;
}
.message-section{
    width: 100%;
    display: flex;
}
.message{
    width: 70%;
    color: #fff;
    background-color: rgb(7, 65, 83);
    padding: 1rem;
    text-align: justify;
    text-justify: inter-word;
}
.contact{
    width: 100%;
    margin:auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.contact i{
    color: #074153;
}
.contact span{
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    margin: 3px auto;
}
.contact a{
    text-decoration: none;
    color: #000;
}
.message p:not(:last-child){
    margin-bottom: 20px;
}
@media only screen and (max-width:600px){
    .overview, .infra{
        display: block;
    }
    .image-box{
        object-fit: cover;
        display: none;
    }
    .content{
        width: 100%;
        padding-bottom: 1rem;
    }

    /* Message section */

    .message-section{
        display: block;
    }
    .image-section{
        width: 90%;
        display: flex;
    }
    .message{
        width: 100%;
        padding: 0;
    }
    .message p{
        width: 80%;
        padding: 0;
        margin: auto;
    }
    .info{
        font-size: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .info .contact{
        display: none;
    }
    .message p:first-child{
        padding-top:15px;
    }

    .message p:last-child{
        padding-bottom:15px;
    }
}

