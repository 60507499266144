.CoursesList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.CourseContainer {
    background-color: #074153;
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    max-width: 40vw;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CourseImage {
    width: 40vw;
    border-radius: 10px 10px 0px 0px;
}

.CourseHead {
    text-align: center;
    font-size: 2em;
    font-weight: 700;
    padding: 10px;
}

.CourseDetails {
    padding: 20px;
    text-align: justify;
}

.CourseCaption {
    background-color: #2b8bf8;
    height: 50px;
    border-radius:  0px 0px 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.CaptionItem h4 {
    display: inline;

}

.CaptionItemIcon {
    height: 30px;
    vertical-align: middle;
    margin: 0px 5px;
}

.CurriculumContainer {
    padding: 20px 0px;
}

.CurriculumBody {
    padding: 10px 100px;
    background-image: url("../images/course/card_1.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.CourseSlideHolder {
    height: 580px !important;
    width: calc(100vw - 210px) !important;
}

.CourseSlideContainer {
    background-color: rgba(255, 255, 255, 0.90);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin: 40px;
    border-radius: 8%;
    min-width: 400px;
    min-height: 500px;
    max-width: 400px;
    max-height: 500px;
}

.SemesterHead {
    position: relative;
    top: -25px;
    left: 3px;
    background: #09526f;
    border-radius: 20px;
    font-size: 1.5em;
    color: white;
    padding: 20px;
    text-align: center;  
    width: 60%;
}

.SemesterContent {
    border-radius: 10px;
    padding: 0px 20px;
}

.SemesterContent h2{
    font-size: 1.2em;
    font-weight: 600;
    margin: 5px 0px;
    border-bottom: 2px solid #074153; 
    width: max-content;  
}

.SemesterContent h3{
    font-size: 1.0em;
    font-weight: 400;
    margin: 0;   
}

@media only screen and (max-width: 1080px) {  
    .CoursesList {
        flex-direction: column;
        align-items: center;
    }
    
    .CourseContainer {
        max-width: 90vw;
        margin: 20px 0px;
    }
    
    .CourseImage {
        width: 90vw;
    }
    
    .CourseHead {
        font-size: 1.5em;
        padding: 5px;
    }
 
    .CourseDetails {
        font-size: 0.9em;
        padding: 10px 15px;
    }
    
    .CourseCaption {
        height: 40px;
    }
    
    .CaptionItemIcon {
        height: 20px;
    }
    
    .CurriculumContainer {
        padding: 10px 0px;
    }
    
    .CurriculumBody {
        padding: 10px 10px;
        background-image: url("../images/course/card_1_mobile.jpg");
    }
    
    .CourseSlideHolder {
        height: 530px !important;
        width: calc(100vw - 20px) !important;
    }
    
    .CourseSlideContainer {
        margin: 40px 10px;
        border-radius: 8%;
        min-width: 350px;
        min-height: 450px;
        max-width: 350px;
        max-height: 450px;
    }
    
    .SemesterHead {
        font-size: 1.3em;
        width: 60%;
    }
    
    .SemesterContent {
        border-radius: 10px;
        padding: 0px 10px;
    }
    
    .SemesterContent h2{
        font-size: 1.1em; 
    }
    
    .SemesterContent h3{
        font-size: 0.9em;
    }    
}
