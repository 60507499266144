/* All style sheets follow desktop first layout */

html,
body,
#root,
#App {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.CourseButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.CourseButton {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.CourseButton.active {
  background-color: rgb(3, 119, 165);
  color: white;
}

.CourseButton:hover {
  background-color: #e0e0e0;
}

.CourseButton.active:hover {
  background-color: #074153;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff00;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #bebebe;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(73, 73, 73);
}

#AppBody {
  height: calc(
    100% - 40px
  ); /* find available height for page, total height - header's height */
  width: 100%;
  overflow: auto;
}

.Page {
  display: table;
  height: 100%;
  width: 100%;
}

.PageContainer {
  display: table-row;
}

.AlignCenter {
  text-align: center !important;
}

.marginPaddingNone {
  margin: 0 !important;
  padding: 0 !important;
}

.heading-container {
  width: 100%;
  padding: 2rem 0 1rem 0;
  text-align: center;
}

.heading-border {
  width: 7%;
  background-color: #074153;
  height: 5px;
  border-radius: 5px;
  margin: 1rem auto;
}

.subheading-container {
  width: max-content;
  text-align: left;
}

.subheading-border {
  width: 110%;
  background-color: rgb(7, 65, 83);
  height: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 1080px) {
  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  .AppBody {
    height: calc(
      100% - 60px
    ); /* find available height for page, total height - header's height */
  }

  .heading-container {
    padding: 1rem 0 1rem 0;
  }
  .heading-container h1 {
    font-size: 1.5em;
  }

  .heading-border {
    width: 7%;
    background-color: rgb(7, 65, 83);
    height: 5px;
    border-radius: 5px;
    margin: 0.5rem auto;
  }

  .subheading-container {
    width: max-content;
    text-align: left;
  }

  .subheading-container h2 {
    font-size: 1.2em;
  }

  .subheading-border {
    width: 110%;
    background-color: rgb(7, 65, 83);
    height: 5px;
    border-radius: 5px;
  }
}
